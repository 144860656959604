<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-img :src="require('@/assets/logo.png')" class="center mb-4" height="60" contain></v-img>
        <v-card outlined class="pa-6 ma-auto" max-width="360px" elevation="4">
          <h1 class="text-center">Forgot</h1>

          <v-text-field :disabled="loading" v-model="email" append-icon="mdi-mail" label="Email" required @keyup.enter="requestResetPassword()"></v-text-field>
          <v-btn :loading="loading" block color="primary" class="my-4" x-large @click="requestResetPassword()">Request Reset Password</v-btn>
          <v-btn class="mr-4 my-2" block link :to="{ name: 'Login' }" x-large> Back to Login </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="320">
      <v-card color="success" dark> 
        <v-card-title>Success</v-card-title>
        <v-card-text>
          <v-alert type="success">
            {{ alertMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {

  data: () => ({
    dialog: false,
    alertMessage: "",
    email: "",
    loading: false,
  }),


  methods: {

    requestResetPassword() {
      this.loading = true;

      this.showLoadingOverlay(true);

      var formData = new FormData();
      formData.append("email", this.email);

      this.$axios.post("auth/forgot/request", formData).then((res) => {
        this.showLoadingOverlay(false);
        if (res.data.status == "success") {
          this.dialog = true;
          this.alertMessage = res.data.message;
          this.email = "";
        } else {
          this.showAlert(res.data.status, res.data.message);
        }
        this.loading = false;
      })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    },
  },
};
</script>